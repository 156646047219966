//colors

$green: #18D858;
$yellow: #F8C800;
$silver: #C0D0F0;
$red: #FC2860;
$blue: #686091;
$dark_b: #181430;
$linear: linear-gradient( 76.47deg, #201840 0%, #5c5494 327.72%);
$white: #fff;
$b_color: rgba(40, 32, 72, .6);

//font
$f_12: .625rem;
$f_14: .7292rem;
$f_18: .9375rem;
$f_24: 1.25rem;
$f_36: 1.875rem;
$f_48: 2.5rem;
$f_64: 3.3rem;


$font:'Raleway', sans-serif;
$roboto:'Roboto', sans-serif;