div,
h1,
h2,
h3,
h4,
h5,
hgroup,
article,
section,
figure,
textarea,
svg,
img,
label,
input,
form,
button,
main,
aside,
nav,
a,
header,
footer,
span,
p,
strong,
ul,
li,
ol,
body,
html,
footer,
i,
table,
tr,
td {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  font-weight: normal;
  border: 0;
  outline: 0;
  color: $white;
  font-family: $font;
}

html{
  font-size: 1vw;

  @media (max-width: 640px){
    font-size: 4vw;
  }
}