.left_side {
  width: 25%;
  background: $dark_b;
  padding-top: 4.16vw;
  transition: all .6s ease-in-out;

  .close {
    display: none;
  }

  @media (max-width: 640px) {
    width: 100%;
    position: fixed;
    left: -100%;
    top: 0;
    min-height: 100vh;
    z-index: 10;

    &.active{
      left: 0;
    }

    .close {
      display: block;
      position: absolute;
      top: 0px;
      right: 16px;
      color: $white;
      font-size: 44px;

    }
  }

  a.logo {
    width: 3.3vw;
    display: flex;
    margin-left: auto;
    margin-right: auto;


    @media (max-width: 640px) {
      width: 20%;
    }

    img {
      width: 100%;
    }
  }

  nav {
    margin-top: 9.17vw;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;

    a {
      width: 100%;
      margin-left: 2.5vw;
      font-size: $f_24;
      padding-top: 0.55vw;
      padding-bottom: 0.55vw;
      display: flex;
      align-items: center;

      @media (max-width: 640px) {
        margin-left: 6vw;
        margin-top: 3vw;
      }


      &:before {
        content: '';
        position: absolute;
        left: 0;
        font-weight: normal;
        display: inline-flex;
        min-height: 3.3vw;
        width: .65vw;
        background: $red;
        opacity: 0;
        box-shadow: 2px 0px 24px #FC2860;


        @media (max-width: 640px) {
          min-height: 10vw;
          width: 2vw;
        }
      }

      &:hover,
      &.active {
        font-weight: bold;

        &:before {
          opacity: 1;
        }
      }
    }
  }
}

.right_side {
  width: 75%;
  background-image: $linear;
  padding-top: 4.16vw;
  padding-left: 4.16vw;
  padding-right: 4.16vw;
  padding-bottom: 5.833333333333333vw;
  min-height: 100vh;

  .open_menu{
    display: none;
  }


  @media (max-width: 640px){
    width: 100%;
    padding-top: 6vw;

    .open_menu{
      position: absolute;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      width: 10%;
      margin-top: 1vw;

      span{
        width: 100%;
        padding-bottom: .8vw;
        background: $white;
        margin-bottom: 2vw;
      }
    }
  }


  header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;


    @media (max-width: 640px){
      flex-wrap: wrap;
    }
  }

  h1 {
    line-height: 1;
    font-size: $f_64;
    font-weight: 300;


    @media (max-width: 640px){
      order: 1;
      padding-top: 6vw;
      padding-bottom: 6vw;
      width: 80%;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .login_bar {
    margin-left: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .lang {
      margin-right: 2.916vw;
      position: relative;


      @media (max-width: 640px){
        display: none;
      }

      &:after {
        content: '';
        width: 0px;
        height: 0px;
        position: absolute;
        border: .3vw solid transparent;
        border-right: .3vw solid $white;
        border-top: .3vw solid $white;
        transform: rotate(135deg);
        right: -1vw;
        top: 10%;
      }


      li {
        cursor: pointer;
        display: none;
        font-weight: bold;
        font-size: $f_18;

        &.active {
          display: block;
        }
      }
    }
  }
}

.btn_default {
  font-size: $f_18;
  padding: 0.417vw;
  padding-left: .8vw;
  padding-right: .8vw;
  background-image: linear-gradient(180deg, #383068 0%, #8078D0 100%);
  border-radius: 4px;
  box-shadow: 0px 8px 24px rgba(128, 112, 248, 0.48);
  font-family: $roboto;


  @media (max-width: 640px){
    padding-left: 4vw;
    padding-right: 4vw;
    padding-top: 2vw;
    padding-bottom: 2vw;
  }

  &:hover {
    box-shadow: none;
  }
}

.status_bar {
  margin-top: 2.083vw;
  background: $dark_b;
  border-radius: 16px;
  padding: 1.25vw;
  display: flex;

  @media (max-width: 640px){
    padding: 4vw;
    flex-wrap: wrap;
  }

  .col {
    border-right: 0.09vw solid $b_color;
    padding-left: 1.25vw;
    padding-right: 1.25vw;
    width: 22%;

    @media (max-width: 640px){
      width: 50%;
      border-right: none;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    &.grow-1 {
      width: auto;
      flex-grow: 1;

      @media (max-width: 640px){
        display: none;
      }

    }

    h2 {
      font-style: normal;
      font-weight: 500;
      font-size: $f_18;
    }

    .count {
      font-style: normal;
      font-weight: 300;
      font-size: $f_48;
      margin-top: 0.83vw;
      display: inline-flex;

      &.green {
        color: $green;
      }
    }

    .best_player {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border: 0.09vw solid $b_color;
      border-radius: 4px;
      padding: 1.25vw;
      padding-top: .525vw;
      padding-bottom: .525vw;
      margin-top: 0.83vw;

      span.orange {
        font-weight: 500;
        font-size: 24px;
        color: $yellow;
        padding-right: 1.25vw;
      }

      .hesh_nums {
        font-weight: normal;
        font-size: $f_14;
        text-decoration-line: underline;
        color: #5868F8;
        padding-right: 2.5vw;
        display: inline-flex;
      }

      .top_bet {
        padding-right: 1.25vw;
        display: flex;
        flex-direction: column;
        margin-left: auto;

        .title {
          font-style: normal;
          font-weight: normal;
          font-size: $f_14;
          display: flex;
          align-items: center;
          color: $blue;
        }

        .eth_values {
          font-style: normal;
          font-weight: normal;
          font-size: $f_12;

          strong {
            font-style: normal;
            font-weight: bold;
            font-size: $f_18;
          }
        }

        &.yellow {
          margin-left: 0;
          padding-right: 0;

          .title {
            color: $yellow;
          }

          .eth_values {
            color: $yellow;

            strong {
              color: $yellow;
            }
          }
        }
      }
    }

    &:last-child {
      border-right: 0;
    }
  }
}

.cases {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 2.083vw;

  @media (max-width: 640px){
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 4vw;
  }

  .item {
    width: 20.8vw;
    background: #101020;
    opacity: 0.8;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    border: .1vw solid #5971bf;
    padding: 2.5vw;
    position: relative;

    @media (max-width: 640px){
      width: 80%;
      margin-bottom: 4vw;
      padding: 4vw;
    }


    &:before {
      content: '';
      position: absolute;
      top: -.16vw;
      left: -.156vw;
      width: 101.6%;
      height: 4.8vw;
      background: transparent url('../img/top_ar.png') top no-repeat;
      background-size: 100%;
      transform: rotate(180deg);
      z-index: 10;
      opacity: 0;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: -.16vw;
      left: -.156vw;
      width: 101.6%;
      height: 4.8vw;
      background: transparent url('../img/top_ar.png') top no-repeat;
      background-size: 100%;
      z-index: 10;
      opacity: 0;
    }

    &:hover {
      &:before,
      &:after {
        opacity: 1;

        @media (max-width: 640px){
          opacity: 0;
        }
      }
    }

    .header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .status_prices {

        &:last-child {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-end;
        }

        .title {
          font-style: normal;
          font-weight: normal;
          font-size: $f_14;
          display: flex;
          align-items: center;
          color: $blue;

          @media (max-width: 640px){
            font-size: $f_18;
          }
        }

        .price {
          font-style: normal;
          font-weight: bold;
          font-size: $f_18;
          text-transform: uppercase;
        }
      }
    }

    figure {
      margin-top: -1.25vw;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
      }
    }

    .case {
      width: 100%;
      font-style: normal;
      font-weight: bold;
      text-align: center;
      font-size: $f_18;
      letter-spacing: 0.24em;
      text-transform: uppercase;
      margin-top: -2.5vw;

      @media (max-width: 640px){
        margin-top: -10vw;
      }
    }

    .name_case {
      width: 100%;
      font-style: normal;
      font-weight: bold;
      font-size: $f_48;
      text-align: center;
      color: $red;

      &.yellow {
        color: $yellow;
      }

      &.silver {
        color: $silver;
      }
    }

    .description {
      font-style: normal;
      font-weight: normal;
      font-size: $f_18;
      padding-top: .635rem;
      text-align: center;
      width: 100%;

      strong {
        font-weight: 600;
      }
    }

    .btn_default {
      width: 50%;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      cursor: pointer;
      margin-top: 1.25vw;
      z-index: 10000;

      @media (max-width: 640px){
        margin-top: 6vw;
      }
    }
  }
}

.info_for_case {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8vw;
  position: relative;
  padding-bottom: 8vw;

  @media (max-width: 640px){
    flex-wrap: wrap;
  }

  .loading-content {
    display: none;
    color: $yellow;
    font-size: 22px;
    border: 4px solid;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 15px;
  }

  .col {
    width: 33%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    z-index: 2;

    @media (max-width: 640px){
      width: 100%;
    }

    .presents {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      padding-top: 1.25vw;

      .item {
        width: 33%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-bottom: 1.14583vw;

        @media (max-width: 640x){
          padding-bottom: 4vw;
        }

        img {
          width: 30%;
          margin-right: .625vw;
        }
      }
    }

    h2 {
      font-weight: 300;
      font-size: $f_36;
      width: 100%;
      font-family: $roboto;
      padding-bottom: 4vw;

      strong {
        font-size: inherit;
        font-weight: 700;
      }
    }

    &:firs-child {
      width: 25%;

      @media (max-width: 640px){
        width: 100%;
      }

    }

    &.ltr {
      @media (max-width: 640x){
        width: 100%;
      }

      h2 {
        text-align: right;
        padding-right: 0;
        margin-right: 0;

        @media (max-width: 640px){
          text-align: left;
        }
      }

      .list {
        padding-top: 1.25vw;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;

        @media (max-width: 640px){
          justify-content: flex-start;
          align-items: flex-start;
        }

        .item {
          width: 100%;
          padding: .55vw;
          border: .1vw solid $white;
          border-radius: 4px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 0.416vw;

          @media (max-width: 640px){

            width: 97%;
            border-width: 2px;
            padding: 3vw;
            margin-bottom: 4vw;
            box-sizing: border-box;
            flex-wrap: wrap;
            justify-content: center;
          }

          .hesh {
            color: $blue;
            font-size: $f_12;
            text-decoration: underline;
            margin-right: .55vw;

            @media (max-width: 640px){
              font-size: $f_18;
              margin-right: 0;
              padding-bottom: 4vw;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 100%;
              color: lighten($blue, 50%);
            }
          }

          .combiner {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-style: normal;
            font-weight: bold;
            font-size: $f_14;
            line-height: 144%;
            font-family: $roboto;

            @media (max-width: 640px){
              font-size: $f_18;
              width: 50%;
              justify-content: center;
              align-items: center;
            }

            img {
              width: 2.083vw;
              margin-right: .55vw;

              @media (max-width: 640px){
                width: 10vw;
                margin-right: 2vw;
              }
            }
          }
        }
      }

    }

    &.case_back {
      position: absolute;
      width: 28.8547vw;
      height: 28.8547vw;
      left: calc(50% - 14.4224vw);
      background: transparent url('../img/case_shadow.png') center no-repeat;
      background-size: 108%;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      z-index: 1;

      @media (max-width: 640px){
        position: static;
        width: 100%;
        height: 80vw;
        padding-bottom: 6vw;
        justify-content: center;
        align-items: flex-end;
      }

      .btn_default {
        position: absolute;
        bottom: 10%;
        left: 35%;

        @media (max-width: 640px){
          position: static;
          margin-top: auto;
          margin-right: auto;
          margin-left: auto;
        }
      }
    }
  }
}

.description_about_us {
  width: 100%;
  padding-top: 1.25vw;

  .orange {
    color: $yellow;
    font-style: normal;
    font-weight: bold;
    font-size: $f_18;
    line-height: 144%;
    letter-spacing: 0.24em;
    text-transform: uppercase;
  }

  p {
    padding-top: 2.083vw;
    font-family: $roboto;
    font-style: normal;
    font-weight: normal;
    font-size: $f_18;
    line-height: 144%;

    a {
      padding-top: inherit;
      font-family: inherit;
      font-style: inherit;
      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
      color: #5868F8;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    &.bigger {
      font-size: $f_24;
    }
  }
}

#wrapper {
  width: 100%;
  display: flex;
}

.container {
  width: 100%;
}
